import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import img1 from '@images/definiciones/efficiency.png';
import img2 from '@images/definiciones/equity.png';
import img3 from '@images/definiciones/research.png';
import img4 from '@images/definiciones/shield.png';
import img5 from '@images/definiciones/time-management.png';
import img6 from '@images/definiciones/ux-design.png';
import adorno from "@images/adorno/adorno4.png";

const Wrapper = styled.div`
color: white;
padding-top: 1rem;
display: flex;
flex-direction: column;
align-items: center;
text-align: justify;
`;

const CardContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin-bottom: 2rem;
@media (min-width: 768px){
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
  align-items: stretch;
}
`;

const Card = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding: 1rem 0.5rem 1rem 0.5rem;
margin: 0.5rem;
color:white;

img{
  width: 80px;
  height: 80px;
}
h1{
  font-size: 1rem;
  text-align: center;
}
p{
  text-align: center;
  font-size: 0.8rem;
}

@media (min-width: 768px){
  width: 40%;
}

@media (min-width: 1024px){
  width: 30%;
}

:hover{
  img{
    animation: animateHeart 1.2s infinite;
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.8);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}

`;


const IndexPage = () => {




  return (
    <TemplatePage1
      title={"¿Qué es calidad de atención en salud?"}
      bg={"var(--tema4)"}
      tc={"white"}
      adorno={<Adorno/>}
      hero={true}
      content={(
        <Wrapper >
          <p>La Organización Mundial de la Salud la define como "el grado en que los servicios de salud para las personas y los grupos de población incrementan la probabilidad de alcanzar resultados sanitarios deseados y se ajustan a conocimientos profesionales basados en datos probatorios". Una atención en salud de calidad debe cumplir con estándares satisfactorios en las siguientes dimensiones:  </p>
          <CardContainer>
            <Card>
              <img src={img3} alt={img3}/>
              <h1>EFICACiA</h1>
              <p>Atención basada en investigación</p>
            </Card>
            <Card>
            <img src={img5} alt={img5}/>
              <h1>OPORTUNIDAD</h1>
              <p>Atención que reduce el tiempo de espera y demora</p>
            </Card>
            <Card>
            <img src={img4} alt={img4}/>
              <h1>SEGURIDAD</h1>
              <p>Atención que minimicen el riesgo del paciente</p>
            </Card>
            <Card>
            <img src={img2} alt={img2}/>
              <h1>EQUITATIVOS</h1>
              <p>Atención que no varía de acuerdo a sexo, edad, etc. </p>
            </Card>
            <Card>
            <img src={img6} alt={img6}/>
              <h1>CENTRADOS EN LA PERSONA</h1>
              <p>Atención adecuada a preferencias, necesidades, etc.</p>
            </Card>
            <Card>
            <img src={img1} alt={img1}/>
              <h1>EFICIENCIA</h1>
              <p>Atención que maximiza los beneficios de los recursos disponibles</p>
            </Card>
          </CardContainer>
          <p>
          La calidad de atención en salud debe medirse  para mejorar continuamente. Para ello, la prestación de servicios de salud debe estar basada en datos relevantes que consideren las necesidades y preferencias de los usuarios, es decir, tanto de los pacientes, las familias y comunidades.
          </p>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage






const Adorno = styled.div`
position: absolute;
bottom: 0px;
right: 0;
height:100%;
@media only screen and (min-width:768px){
  bottom: 0px;
right: 400px;
height:100%;
  width: 800px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  bottom: 0px;
left:0;
right:auto;
height:100%;
  width: 600px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
`;